function startAnalytics() {
  if (! isNaN(parseInt(localStorage.getItem("allow-analytics"), 10))) {
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '203353297256864');
    fbq('track', 'PageView');

    window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'UA-116670586-1');
  } else {
    showBanner();
  }
}

function showBanner() {
  var banner = document.getElementById('cookie-banner');
  banner.classList.remove('d-none');
  document.querySelectorAll('.cookie-banner-accept').forEach(function(el) {
    el.addEventListener('click', optIn);
  });
}

function optIn() {
  localStorage.setItem("allow-analytics", new Date().getTime().toString());
  /* TODO: Track consent as GDPR */
  startAnalytics();
  var banner = document.getElementById('cookie-banner');
  banner.classList.add('d-none');
}

window.addEventListener("load", function() {
  startAnalytics();
});

document.addEventListener("scroll", function() {
  optIn();
});
